module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maria","description":"Maria Serra Saez is a visual designer & motion designer based in Barcelona. Award-winning visual and interaction designer, she worked for international agencies providing bold creative solutions, building colorful and beauty visuals with purpose.","short_name":"GatsbyJS","start_url":"/","background_color":"#CF4630","theme_color":"#6b37bf","display":"standalone","icon":"./static/images/favicons/favicon.svg","icon_options":{"purpose":"any maskable"},"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"14726be668c40eb1db358ad06b6fe722"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
